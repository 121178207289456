import styled from '@emotion/styled';
import { darken } from 'polished';

export const Container = styled.aside`
  border-right: 2px solid rgba(0,0,0,0.1);
  width: 20%;
  max-width: 280px;
  min-width: 280px;
  background-color: ${({ theme }) => theme.colors.sidebar.background};

  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  z-index: 2;
  padding-top: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  transition: transform 0.5s;

  top: 60px;
  height: calc(100vh - 60px);

  nav {
    width: 100%;
    align-self: flex-start;
    flex: 1;
  }

  footer {
    padding: 24px 0 24px 30px;
    width: 100%;

    p {
      color: ${({ theme }) => theme.colors.sidebar.footer};
      font-size: 12px;
      margin: 0;
    }
  }

  max-width: 240px;
  min-width: 240px;

  @media (max-width: 780px) {
    z-index: 4;
    padding-top: 60px;
    top: 0;
    max-width: 300px;
    min-width: 300px;
    height: 100vh;
    left: calc(100vw - 300px);
    border-right: initial;
    border-top: initial;
    border-left: 2px solid rgba(0,0,0,0.1);
    transform: translate3d(
      ${({ isMenuOpen }) => (isMenuOpen ? '0' : '100%')},
      0,
      0
    );
  }
`;

export const CloseButton = styled.div`
  width: 34px;
  height: 34px;
  position: absolute;
  top: 20px;
  right: 20px;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
  @media (max-width: 767px) {
    top: 15px;
    right: 15px;
  }
  @media (max-width: 780px) {
    display: initial;
  }
`

export const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 20px 0;

  a {
    width: 100%;
    height: 100%;
    padding-left: 30px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const List = styled.ul`
  list-style: none;
  width: 100%;
  padding-left: 0;
  padding-bottom: 30px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const Heading = styled.li`
  padding-left: 30px;
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 0.142em;
`;

export const Item = styled.li`
  width: 100%;
  transition: all 200ms ease-in-out;
  padding: 0 20px;

  a,
  span {
    display: block;
    font-size: 17px;
    @media (max-width: 780px) {
      font-size: 20px;
    }
    color: ${({ theme }) => theme.colors.sidebar.link};
    background-color: ${({ theme }) => theme.colors.sidebar.background};
    padding: 4px 10px;
    margin: 4px 0;
    border-radius: 4px;
    font-weight: normal;

    text-decoration: none;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    margin: 0 auto;

    transition: background-color 0.2s, color 0.2s, padding-left 0.2s;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    &:not(.active-link):hover {
      padding-left: 20px;
      color: ${({ theme }) => darken('0.2', theme.colors.sidebar.link)};
    }

    &.active-link {
      color: ${({ theme }) => darken('0.2', theme.colors.sidebar.link)};
      background-color: ${({ theme }) => theme.colors.sidebar.itemActive};
    }
  }
`;

export const SubItem = styled(List)`
  margin-top: 5px;
`;