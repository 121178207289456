import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Icon } from "react-icons-kit"
import { mail } from 'react-icons-kit/ikons/mail'
import { user } from 'react-icons-kit/ikons/user'

import HamburgMenu from "common/src/components/HamburgMenu"
import Button from "common/src/components/Button"
import Logo from './Logo';

import Search from "../../../components/Search"

const TitleContainer = styled.div`
  flex: auto;
  margin-left: min(1%, 80px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  a {
    font-size: 18px;
    color: #999999;
    font-weight: 400;
    text-decoration: none;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  height: 100%;
 
  max-height: 100px;
  min-height: 100px;
  min-width: 120px;
  padding: 20px 0;
  @media (max-width: 780px) {
    max-height: 100px;
    min-height: 100px;
    min-width: 100px;
    padding: 0px 0;
  }

  a {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Container = styled.header`
  display: flex;
  align-items: center;

  height: 50px;
  padding: 0 24px;
  @media(max-width: 780px) {
    padding-left: 5px;
  }
  background: #fff;
  position: fixed;
  width: 100vw;
  z-index: 3;
  // border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);

  transition: transform 0.5s;

  h2 {
    margin: 0;
    border: none;
    padding: 0;
    font-size: 18px;
    color: #000;
  }

  button {
    border: none;
    // background: #fff;
    cursor: pointer;
  }
`;

const HamburWrapper = styled.div`
  display: none;
  @media(max-width: 780px) {
    display: initial;
  }
`;

const IconButton = styled(Button)`
  color: gray !important;
  &:hover {
    color: #008abe !important;
  }
`

const btnStyle = {
  minWidth: ['80px', '80px', '80px', '80px'],
  minHeight: '32px',
  fontSize: ['13px', '14px'],
  fontWeight: '500',
  px: '5px',
  py: '0px',
  ml: '10px',
  colors: 'secondaryWithBg',
}

export default function Header({ handleMenuOpen, isMenuOpen }) {
  // TODO: use react-headroom for the header
  return (
    <Container>
      <LogoContainer>
        <Link to={"/#"}>
          <Logo />
        </Link>
      </LogoContainer>
      <TitleContainer>
        <Link to={"/#"}>Home</Link>
      </TitleContainer>
      <Search />
      <Link to="/contact-us">
        <IconButton
          variant="textButton"
          icon={<Icon icon={mail} size={32} />}
          aria-label="contact us"
        />
      </Link>
      <a href="https://app.repairsetup.com" style={{ textDecoration: 'none' }}>
        <Button title="LOGIN" {...btnStyle}
          icon={<Icon icon={user} size={24} />}
          iconPosition="left"
        />
      </a>
      <button
        aria-label="Open sidebar"
        type="button"
        onClick={handleMenuOpen}
        style={{
          outline: "none",
          marginTop: 8,
          marginLeft: 15,
        }}
      >
        <HamburWrapper>
          <HamburgMenu barColor="#008abe" open={isMenuOpen} />
        </HamburWrapper>
      </button>
    </Container>
  );
}

Header.propTypes = {
  handleMenuOpen: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};