import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { saasTheme } from 'common/src/theme/saas';
import TableOfContents from '@rocketseat/gatsby-theme-docs/src/components/Docs/TOC';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { Wrapper, Main, Title, Children, Dimmer } from './styles';
import useLockBodyScroll from "common/src/hooks/useLockBodyScroll"

const BodyLocker = () => {
  useLockBodyScroll()
  return <></>
}

export default function Layout({
  children,
  disableTableOfContents,
  title,
  headings,
  sidebarSection
}) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const disableTOC =
    disableTableOfContents === true || !headings || headings.length === 0;

  function handleMenuOpen() {
    setMenuOpen(!isMenuOpen);
  }

  return (
    <ThemeProvider theme={saasTheme}>
      {isMenuOpen && <BodyLocker />}
      <Dimmer open={isMenuOpen} onClick={() => handleMenuOpen(false)} />
      <Sidebar handleMenuOpen={handleMenuOpen} isMenuOpen={isMenuOpen} />
      <Header handleMenuOpen={handleMenuOpen} isMenuOpen={isMenuOpen} />
      <Wrapper isMenuOpen={isMenuOpen}>
        {sidebarSection && <div id="sidebar-section" style={{ display: "none" }}>{sidebarSection}</div>}
        {title && <Title>{title}</Title>}
        <Main disableTOC={disableTOC}>
          {!disableTOC && <TableOfContents headings={headings} />}
          <Children hasTitle={title}>{children}</Children>
        </Main>
      </Wrapper>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disableTableOfContents: PropTypes.bool,
  title: PropTypes.string,
  headings: PropTypes.array,
};

Layout.defaultProps = {
  disableTableOfContents: false,
  title: '',
  headings: null,
};
